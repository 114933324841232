import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";

import { IoClose } from "react-icons/io5";

import Flip from './inputs/Flip'

import './css/New.css'
import { AppContext } from "../../Context";
import Fetch, { Loader } from '../../inc/Fetch'
import Popups from "../../components/PopupNew";
import { Img } from "../../components/Elements";

export default function NewActivity() {

    let ref = useRef();

	const active = useRef(true);
	const params = useParams();

	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [type, settype] = useState(params?.id ? "flip" : false); 
	const [loader, setloader] = useState(params?.id ? true : false); 

    function loadData() {

		Fetch("activity/edit", (d) => {

            setdata(d);

			setloader(false);

		}, params);
    }

	useEffect(() => {
        if (params?.id) {
            loadData();
        }
    }, []);

	useEffect(() => {

        if (type) {
            ref.current?.scrollTo({
                top: 0,
                left: 1000,
                behavior: "smooth",
            });
        } else {
            ref.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [type]);
	
	
	return (
		
		<Popups setOpen={setopen} style={{maxWidth:700, borderTop:"4px solid #fd745a", maxHeight:"96vh"}}>
           <>

                <div className='popupBody interface data GeneratorNew' >
          
                    <h1>New activity</h1>
          
                    <div ref={ref} className='newGenScroll' >
                        
                        {!params?.id && <div className='newGenCatsBox'>
                            <a onClick={() => settype("flip")}>
                                <Img src={''} />
                                <label>
                                    <h3>Flip tiles</h3>
                                    <p>you predict the outcome of a coin flip. Here's how it generally works</p>
                                </label>
                            </a>

                            <Link>
                                <Img src={''} />
                                <label>
                                    <h3>Flip tiles</h3>
                                    <p>you predict the outcome of a coin flip. Here's how it generally works</p>
                                </label>
                            </Link>

                            <Link>
                                <Img src={''} />
                                <label>
                                    <h3>Flip tiles</h3>
                                    <p>you predict the outcome of a coin flip. Here's how it generally works</p>
                                </label>
                            </Link>


                            <Link>
                                <Img src={''} />
                                <label>
                                    <h3>Flip tiles</h3>
                                    <p>you predict the outcome of a coin flip. Here's how it generally works</p>
                                </label>
                            </Link>

                        </div>}

                        <div className='newGenInput'>
                            {type == "flip" && <Flip data={data} />}
                        </div>

                    </div>
                    
                </div>
            </> 

            

        </Popups>
	)
}
