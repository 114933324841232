import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";

import './css/activities.css'
import { AppContext } from "../Context";
import Activity from "./Generator/activity";
import Fetch, { Loader } from '../inc/Fetch'

import Footer from "./Footer";
import Header from "./Header";

import { IoIosAdd } from "react-icons/io";


export default function Activities() {

	const location = useLocation();
	const active = useRef(true);

	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	const [loader, setloader] = useState(false); 
	

	function loadData() {
		setloader(true);
		Fetch("activity/view", (d) => {
			setdata(d?.activities);

			setpageLoad(false);
			setloader(false);

		}, { filter:filter});
	}
	
	function del(id) {
		let cof = window.confirm("Are you sure you want to delete this activity? This action cannot be undone.");
		if (!cof) {
			return false;
		}
		setloader(true);
		Fetch("activity/del", (d) => {

			loadData();
			setloader(false);

		}, { _id:id});
	}
	
	useEffect(() => {

		let arrClas = [{ name: `- ${text?.all_classes} -`, val:false}];
		Object.keys(global.Classes)?.map((v) => {
			arrClas.push({ name: global.Classes[v], val:v});
		});
		setcls(arrClas);

		loadData();
		

	}, [filter, text?.all_categories, location]);


	

	return (
		
		<>
			{loader && <Loader />}
			<Header title="Activities" />
			<div className='firstElem search activitiesHeadBox'>
				<div className='mainWidth'>

					<div className='filterBoxTop'>

						{/* <Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cats}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                cats:v,
                            }))}
                            value={filter?.cats}
                        />

						<Input 
                            type="select"
                            title="" 
                            name="level"
                            validate={true}
                            values={cls}
                            onChange={(v) => setfilter(p => ({
                                ...p,
                                classes:v,
                            }))}
                            value={filter?.classes}
                        /> */}
			
						

					</div>

					
					<Link to='/new_activity' state={{ previousLocation: location }} className='btn' ><IoIosAdd />New activity</Link>
				</div>
			</div>


			<section className='sect5 tasks' style={{padding:0}}>

				{pageLoad ? <Loader /> :

				<div className='mainWidth'>

					{!data?.length ? <div className='noData'>It looks like there are no activities in this section at the moment.</div>:false}
					
					<div className='sect5Box'>
						{data?.length ? data?.map((v, k) => {
							
							return (
								<Activity key={k} del={del} data={v} />
							);
						})
						
						: false}

					</div>
					
					
				</div>
				
				}


				
			</section>

			<Footer />
		</>
	)
}
