import { memo, useEffect, useState, useContext, useRef } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import TextTruncate from 'react-text-truncate';

import { MdDeleteOutline,MdEdit } from "react-icons/md";

import { AppContext } from "../../Context";
import { Img } from "../../components/Elements";

export default function Activity({data, del}) {

    const location = useLocation();
    // console.log(data);
    
    const {success, userinfo, showQuiz} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [showQuizD, setshowQuizD] = showQuiz;

    const [loader, setloader] = useState(false);

    
    return (
        <>
            
            <div className='gameListBox activity'>
                
                <nav className='editMenu'>
                    <Link onClick={() => del(data?._id)}><MdDeleteOutline className='delete' /></Link>
                    <Link state={{ previousLocation: location }} to={`/new_activity/${data?._id}`}><MdEdit className='edit' /></Link>
                </nav>
                
                <Link target='_blabk' to={`/Activity/${data?._id}`}>
                    {/* <b className='lockedBox'><IoLockClosed /></b> */}
                    <Img src={`img/200x200${data?.img}`} />
                    <p>
                        <h3>{data?.title}</h3>
                        <TextTruncate
                            line={4}
                            truncateText="…"
                            text={data?.full}
                            element="span"
                            textElement={"span"}
                        />
                        
                    </p>
                    
                </Link>
                
                <div>
                    <Link to={`/send_activity/${data?._id}`} state={{ previousLocation: location }} className='btn'>Send activity</Link>
                </div>
            </div>
        </>
    );
}