import { useEffect, useState, useContext, useRef} from 'react'
import { useNavigate } from "react-router-dom";

import { FaRegImage } from "react-icons/fa";
import { FaSort, FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";

import { AppContext } from "../../../Context";
import { Input, MultiSelect, FormUpload } from "../../../components/Form";
import { Img } from "../../../components/Elements";
import Fetch, { Loader } from '../../../inc/Fetch'

import '../css/Flip.css'

export default function Flip({ data }) {
    
    const navigate = useNavigate();
    
    const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;


    const [number, setnumber] = useState(4);
    const [title, settitle] = useState("");
    const [full, setfull] = useState("");
    const [screenloader, setscreenloader] = useState(data?._id ? true : false);
    const [loader, setloader] = useState(false);
    const [values, setvalues] = useState([{}, {}, {}, {}]);
    

    function submit() {
        setloader(true);

        Fetch("activity/save", (d) => { 

            if (d?.error) {
                setopen({ short: d?.error, error: true });
            } else {

                setopen({ error: false });
                setTimeout(() => {
                    navigate(-1);
                }, 2500);
                
            }
            
            
            setloader(false);
        }, { answers:values, title:title, full:full, _id: data?._id });
    }

    function addField() {

        if (values?.length >= 30) {
            setopen({ short: "You must provide maximum of 30 answers.", error: true });
            return false;
        }
         

        setvalues(p => [...p, {}]);
    }

    function updateValues(k, key, val) {
        
        setvalues(p => {

            let arr = [];
            p?.map((v, k2) => {

                if (k2 == k) {
                    v[key] = val;
                }
                arr.push(v);
            });

            return arr;
        });

    }

    function deleteRow(k) {

        let conf = confirm("Are you sure you want to remove this item?");
        if (!conf) {
            return false;
        }

        setvalues(p => p?.filter((v, k2) => k2 != k));

        if (values?.length < 3) {
            setvalues(p => [...p, {}]);
        }
        
    }


    useEffect(() => {
        if (data?._id) {
            settitle(data?.title);
            setfull(data?.full);
            
            setvalues(data?.answers);
            setscreenloader(false);
            
        }
    }, [data?._id]);
    
    if (screenloader) {
        return <Loader />
    }
    

    // console.log(values); 
    

    return (
        <div className='newGenInputIn'>

            <div className='newGenTitle'>

                <Input 
                    type="text"
                    title="Activity title" 
                    name="level"
                    validate={true}
                    onChange={(v) => settitle(v)}
                    value={title}
                />

                <Input 
                    type="textarea"
                    title="Instructions" 
                    name="level"
                    // validate={true}
                    onChange={(v) => setfull(v)}
                    value={full}
                />

            </div>

            <div className='newGenOptions'>

                <div className='newGenTable'>
                    <strong></strong>
                    <div>
                        <b>Front</b>
                    </div>
                    <div>
                        <b>Back</b>
                    </div>
                    <ul></ul>
                </div>

                {values?.map((v, k) => {
                    
                    return(
                        <div className='newGenTable genBody'>
                            <strong>{k + 1}.</strong>
                            <div>
                                {v?.front_img && <Img src={v?.front_img}/>}
                                <input type='text' value={v?.front || ""} onChange={d => 
                                    updateValues(k, "front", d?.target?.value)
                                }/>
                                <label>
                                    <FormUpload onChange={d => updateValues(k, "front_img", d)}/>
                                    <FaRegImage />
                                </label>
                            </div>
                            <div>
                                {v?.back_img && <Img src={v?.back_img}/>}
                                <input type='text' value={v?.back || ""}  onChange={d => 
                                    updateValues(k, "back", d?.target?.value)
                                }/>
                                <label>
                                    <FormUpload onChange={d => updateValues(k, "back_img", d)}/>
                                    <FaRegImage />
                                </label>
                            </div>

                            <ul>
                                <FaSort />
                                <MdDelete onClick={() => deleteRow(k)}/>
                            </ul>
                        </div>
                    );
                })}

                <a onClick={addField} className='newGenMore'>
                    <FaPlus /> Add new item <span style={{fontSize:10}}>(Max 30)</span>
                </a>

                <div style={{display:"flex", justifyContent:"end"}}>
                    <button onClick={submit} className='btn'>Save activity</button>
                </div>
                

            </div>
        </div>
    );
}