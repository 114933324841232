import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";


import './css/Cp.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";

import Footer from "./Footer";
import Header from "./Header";
import SendTask from "./popups/SendTask";
import { Input, MultiSelect } from "../components/Form";

import { RiQuestionAnswerLine } from "react-icons/ri"; 
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup, MdOutlineSearch } from "react-icons/md";


export default function MyTasks() {

	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [loader, setloader] = useState(true);
	const [data, setdata] = useState([]);
	const [showsendTask, setshowsendTask] = useState(false); 
	const [filterOpen, setfilterOpen] = useState(false); 
	const [classes, setclasses] = useState([]); 
	const [values, setvalues] = useState({}); 

	let loc = useParams();
	let nav = useLocation();

	function deleteTask(v) { 

		let conf = window.confirm(text?.task_confirm_delete);
		if (!conf) {
			return false;
		}

		Fetch("tasks/delete", (d) => {

			setdata(p => {
				let arr = [];
				p?.tasks?.map((r) =>{
					if (r?._id != v) {
						arr.push(r);
					}
					
				});
				
				
				return { stats:p?.stats, tasks:arr}
			});

			setloader(false);
		}, { _id: v });

	}


	useEffect(() => {

	

		if (!showsendTask) {
			
			Fetch("tasks/view", (d) => {

				if (!d?.error) {
					setdata(d);

					let arr = [
						{
							name: `- ${text?.classes} -`,
							val:false
						}
					];

					logedIn?.userinfo?.classes?.map(v => {
						arr.push({ name:global.Classes[v[0]] + ` (${global.Grades[v[2]]})`, val:v });
					});

					setclasses(arr);
				}
	
				setloader(false);
			}, {filter: values});
			
			
		}
		

	}, [showsendTask, values]);

	
	if (loader) {
		return (
			<Loader />
		);
	}

	let path = [
		{
			name:text?.home,
			link:"/"
		},
		{
			name:text?.my_tasks,
			link:"/MyTasks"
		}
	];

	let statusList = [
		{
			name:`- Status -`,
			val:false
		},
		{
			name:text?.waiting,
			val:1
		},
		{
			name:text?.nothing_new,
			val:2
		}
	];

	return (
		
		<>
			{showsendTask && <SendTask setopen={setshowsendTask} open={showsendTask} />}

			<Header title={path} />


			<div className='firstElem stats'>

				<div className='mainWidth'>


					<section>
						<div>
							<b>{text?.stats_myStudents}</b>
							<h3>{data?.stats?.students}</h3>
							{/* <span className='up'><FaArrowUp /> 10.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>{text?.stats_grade_avg}</b>
							<h3>{data?.stats?.avg}%</h3>
							{/* <span><FaArrowDown /> 2.3%</span> */}
						</div>
					</section>

					<section>
						<div>
							<b>Participants</b>
							<h3>{data?.stats?.sent}</h3>
							{/* <span><FaArrowUp /> 1.3%</span> */}
						</div>
					</section>


				</div>
			</div>


			<div className='body'>
				<div className='mainWidth '>

					<div className='cp'>



						<div className='containerBox' style={{flex:1, }}>
					
							<hr className='boxLine'/>
							<h3 className='boxTitle' style={{fontSize:20, color:"#555", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
								Tasks

								<div className={`searchTasksBox `}>
									<Input 
										title="" 
										name="Classes"
										type="select"
										
										onChange={(v) => setvalues(p => ({
											...p,
											classes:v,
										}))}
										style={{padding:0, maxWidth:200}}
										values={classes}
										value={values?.classes}
									/>

									<Input 
										title="" 
										name="Status"
										type="select"
										
										onChange={(v) => setvalues(p => ({
											...p,
											status:v,
										}))}
										style={{padding:0, maxWidth:200}}
										values={statusList}
										value={values?.status}
									/>
								</div>

								{/* <a className='btnFilter' onClick={() => setfilterOpen(p => !p)} title='Filter'>
									<MdOutlineSearch />
								</a> */}
							</h3>
							
							<div className='quizzesList'>

								<div className={`filterBox ${filterOpen && 'open'}`}>
									<Input 
										title="" 
										name="Classes"
										type="select"
										
										onChange={(v) => setvalues(p => ({
											...p,
											classes:v,
										}))}
										style={{padding:0, maxWidth:200}}
										values={classes}
										value={values?.classes}
									/>

									<Input 
										title="" 
										name="Status"
										type="select"
										
										onChange={(v) => setvalues(p => ({
											...p,
											status:v,
										}))}
										style={{padding:0, maxWidth:200}}
										values={statusList}
										value={values?.status}
									/>
								</div>

								<table>
									<thead>
										<tr>
											<td style={{width:"35%"}} className='align-left'>Quiz name</td>
											<td >{text?.questions}</td>
											<td>{text?.score}</td>
											<td>{text?.stats_myStudents}</td>
											<td>{text?.classes}</td>
											<td>{text?.status}</td>
											<td style={{width:"13%"}}>{text?.actions}</td>
										</tr>
									</thead>
									
									<tbody>
										{data?.tasks?.length ? data?.tasks?.map((v, k) => {

			
											return (
												<tr key={k} className={`${v?.not ? "checked" : "none"}`}>
													<td className='align-left'><Link to={`/MyTasks/${v?._id}`} ><b>{v?.name}</b> {v?.full && <><br /><p>{v?.full}</p></>}</Link></td>
													<td>{v?.questions?.length}</td>
													<td>{v?.avg ? <span className='tag green'>{v?.avg}%</span> : "--"}</td>
													<td>{v?.students} / {v?.all_students}</td>
													<td>
														{v?.classes?.map((c, kk) => {
		
															let clsSplit = c?.split("-");
															let classNames = `${global.Classes[clsSplit[0]]} (${global.Grades[clsSplit[1]]})`
															return (
																<Link to={`/MyTask/${v?._id}/${c}`} key={kk}><span  className='tag' style={{margin:"4px auto"}}>{classNames}</span></Link>
															);
														})}
													</td>
													<td>{v?.not ? <span className='tag orange' style={{margin:"4px auto"}}>{text?.students} {text?.waiting}..</span> : <span className='tag gray' style={{margin:"4px auto"}}>{text?.nothing_new}</span> }</td>
													<td>
														<Link to={`/MyTasks/${v?._id}`} className='tableOptions blue'><MdOutlineGroup /></Link>
														&nbsp;&nbsp;
														<a href='javascript:void(0)' onClick={() => deleteTask(v?._id)} className='tableOptions red'><MdDeleteOutline /></a>
														&nbsp;&nbsp;
														<a href='javascript:void(0)' onClick={() => setshowsendTask(v)} className='tableOptions green'><MdEdit /></a>
														
														
													</td>
												</tr>
											);
										}): false}
										
									</tbody>
								</table>
								{!data?.tasks?.length && <div className='errorBox'>{text?.cant_find_tasks}</div>}
							</div>
							
						</div>
					</div>


						
					
					
				</div>


			</div>

			<Footer />
		</>
	)
	
}
