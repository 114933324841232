import { memo, useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import './css/activity_send.css'
import manSrc from '../../images/moe.png'
import { Editor } from '@tinymce/tinymce-react';

import { AppContext } from "../../Context";
import Popups from "../../components/Popups";

import Fetch, { Loader, dateToText } from "../../inc/Fetch";
import { Input, MultiSelect } from "../../components/Form";

import { MdDeleteOutline } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";


export default function SendTasks({setopen, open}) {

    
    
    const params = useParams();
    const navigate = useNavigate();


    const {success, userinfo, languageText, showQuestion, cartData} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;

    const [miniTabs, setminiTabs] = useState(0);

    const [values, setvalues] = useState({ activity: params?.id });
    const [loader, setloader] = useState(false);
    const [questions, setquestions] = useState([]);
    const [story, setstory] = useState([]);

    const [ploader, setploader] = useState(open?._id ? true : false);

    const [myClasses, setmyClasses] = useState([]);

    const [openNewQuestion, setopenNewQuestion] = useState(false);
    const [students, setstudents] = useState([]);

    const [checked, setchecked] = useState([]);



    function submit(e) {
        e.preventDefault();

        setloader(true);

        Fetch("activity/send", (d) => {

            if (d?.error) {
                setsuc({
                    title:"Ops!!",
                    short:d?.error,
                    error:true
                });
            } else {
                setsuc({
                    title:"Wow!",
                    short:"The task was sent successfully.",
                    error:false,
                    redirect:"/MyTasks"
                });

                setTimeout(() => {
                    navigate(-1);
                }, 2500);


            }

            setloader(false);
        }, values);

    }

    function loadStudent(v) {

        setvalues(p => ({
            ...p,
            classes:v,
        }));


        Fetch("students/view", (d) => {

            if (d?.students?.length) {
                let arr = [];
                d?.students?.map((v) => {
                    arr.push({ name: v?.name, val:v?._id});
                });
                setstudents(arr);
            }

            setloader(false);
        }, { classes: v});
    }

    function timeToInput(dS) {

        let Month = (new Date(dS).getMonth() + 1);
        Month = Month < 10 ? `0${Month}` : Month;
        
        let Day = new Date(dS).getDate();
        Day = Day < 10 ? `0${Day}` : Day;
        
        let Hour = new Date(dS).getHours();
        Hour = Hour < 10 ? `0${Hour}` : Hour;
        
        let Min = new Date(dS).getMinutes();
        Min = Min < 10 ? `0${Min}` : Min;
        
        
        
        let date_start = `${new Date().getFullYear()}-${Month}-${Day}T${Hour}:${Min}`;

        return date_start;
    }




    useEffect(() => {
        let clsArr = [];

        if (!logedIn?.userinfo?._id) {
            setploader(true);
            setsuc({
                title:"Ops!!",
                short:"You are not logged in, you do not have a permission to access this section.",
                error:true
            });

            setTimeout(() => {
                setopen(false);
                setploader(false);
            }, 2500);

        } else {

            logedIn?.userinfo?.classes?.map(v => {
                let spCls = v?.split("-");
                clsArr.push({ name:`${global.Classes[spCls[0]]} (${global.Grades[spCls[1]]})`, val:v });
            });

            
            let stu = [];
            let stu2 = [];
            open?.students_list?.map(v => {
                stu.push(v?._id);
                stu2.push({ name:v?.name, val:v?._id });
            });

            setmyClasses(clsArr);
            setstudents(stu2);

            let dS = open?.date_start || new Date();
            let dE = open?.date_end || false;
            let date_start = timeToInput(dS);
            
            
            if (open?._id) {

                let arr = {
                    _id:open?._id,
                    name: open?.name,
                    classes: open?.classes,
                    date_start: date_start,
                    story: open?.story,
                    timer:open?.timer,
                    res_type:open?.res_type || 0,
                    full:open?.full
                }
                if (dE) {
                    let date_end = timeToInput(dE);
                    arr.date_end = date_end;
                }

                arr.students = stu;

                setvalues(arr);

                setquestions(open?.questions);

                setTimeout(() => {
                    setploader(false);
                }, 100);

            } else {
                
                setvalues(p => ({
                    ...p,
                    date_start:date_start,
                    story:open?.story,
                }));
                setquestions(open?.questions);
            }
        }
            

    }, []);



    

    if (ploader) {
        return (<Loader />);
    }

    return (

        <>

        <Popups back={true} style={{maxWidth:600, borderTop:"4px solid #fd745a", maxHeight:"96vh"}}>
            {loader && <Loader />}
           <>

                <div className='popupBody interface data quizSend' >

                    <div className='dataHead' style={{height:60}}>
                        <nav >
                            <a onClick={() => setminiTabs(0)} className={miniTabs == 0 ? 'checked' : "none"}>Send Task</a>
                        
                        </nav>
                    </div>

                    <form onSubmit={submit}>


                        <div className={`tabsSection ${miniTabs == 0 && "active"}`}>

                            <Input 
                                title="Task name" 
                                name="taskname"
                                type="text"
                                validate={true}
                                
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    name:v,
                                }))}
                                value={values?.name}
                            />


                            <MultiSelect 
                                title="Classes" 
                                name="Classes"
                                type="select"
                                validate={true}
                                
                                onChange={(v) => loadStudent(v)}
                                values={myClasses}
                                value={values?.classes}
                            />

                            {values?.classes?.length ? <MultiSelect 
                                title="Students" 
                                name="students"
                                type="select"
                                
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    students:v,
                                }))}
                                values={students}
                                value={values?.students}
                            />: false}


                            <Input 
                                title="Starts at" 
                                name="date_start"
                                type="datetime-local"
                                placeholder="dd/mm/yy"
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    date_start:v,
                                }))}
                                value={values?.date_start}
                            />

                            <Input 
                                title="Ends at" 
                                name="date_end"
                                type="datetime-local"
                                placeholder="dd/mm/yy"
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    date_end:v,
                                }))}
                                value={values?.date_end}
                            />

                            

                            <div style={{display:"flex", gap:5}}>
                                <button className='btn btnQuizSend'>Send activity</button>
                            </div>
                            
                        </div>


                        



                    </form>
                    

                    
                </div>
            </> 


        </Popups>
        </>
    );
}