import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation, useParams } from "react-router-dom";

import { IoClose } from "react-icons/io5";


import './css/Tasks.css'
import { AppContext } from "../Context";
import Fetch, { Loader } from '../inc/Fetch'
import { Games } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";

export default function Cp() {

	const active = useRef(true);
	const params = useParams();

	const {success, userinfo, showQuiz, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [showQuizD, setshowQuizD] = showQuiz;
	const [text, settext] = languageText;

	const [pageLoad, setpageLoad] = useState(true); 
	const [data, setdata] = useState([]); 
	const [filter, setfilter] = useState({}); 
	const [cats, setcats] = useState([]); 
	const [cls, setcls] = useState([]); 
	
	

	useEffect(() => {

		
		if (active.current != filter) {
			active.current = filter;


			Fetch("Games/view", (d) => {

				if (!d) {
					setopen({
						title:text?.alertErrorTitle,
						short:text?.cant_get_data,
						error:true
					});
				} else {
					setdata(d[0]);
				}

				setpageLoad(false);
			}, { _id: params?.id});
		
		}
	}, [filter]);

	if (pageLoad) {
		return (
			<Loader />
		);
	}
	
	return (
		
		<div className='gameBox'>
			<Link onClick={() => window.close()} className='close'><IoClose /></Link>
			<iframe allow="fullscreen" src={`/game1/index.html?id=${params?.id}`}  />
		</div>
	)
}
