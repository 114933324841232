import { useState, useEffect, useContext } from 'react'

import { Config } from "../inc/Config";
import Fetch, { UploadFile, Loader } from "../inc/Fetch";

import { AppContext } from "../Context";

import './css/Form.css';
import { IoMdArrowDropdown, IoIosCheckbox } from "react-icons/io";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";


export function Input({title, name, validate, type, placeholder, style, error, value, values, onChange, inputStyle, disabled, multiple, autoFocus}) {


    const [focus, setfocus] = useState(false);
    const [v, setv] = useState(value);
    const [selv, setselv] = useState(false);
    const [open, setopen] = useState("closed");
    const [search, setsearch] = useState("");

    const [access, setaccess] = useState(false);

    if (!type) {
        type = "text";
    }

    useEffect(() => {
        if (type == "select" && values?.length) {
            
            if (value || value === 0) {
  
                const selected = values?.filter(p => p.val == value);
                if (selected?.length) {
                    setselv(selected[0]); 
                }
                // setselv(p => p);
            } else {
                setselv(values[0]);
            }
            
        } else {
            
            if (value) {
                setv(value);
            }
            
        }

        

    }, [value]);


    useEffect(() => {

        const closeFunc = (e) => {
            const selClose = e?.target.closest(".selectBox");
            if (!selClose) {
                setopen("closed")
            }
        }

        window.addEventListener("click", closeFunc);

        return () =>{
            window.removeEventListener("click", closeFunc);
        }

    }, []);
    useEffect(() => {
        if (onChange) {
            onChange(v);
        }
    }, [v]);

    

    

    return (
            <label style={style}  className={"formInputBox " + (focus || v || placeholder || type == "select" ? "focused" : "") + (error ? " error" : "")}>
                
                {title && <span>{`${title}:`} {validate && <b>*</b>}</span>}
                {type == "select" ? 
                    <>
                        <div className='selectBox'>
                            <div style={inputStyle} onKeyDown={(e) => {
                                if(e.key === 'Enter'){
                                    setopen(p => p ? "open" : "closed")
                                }
                            }} onClick={() => disabled ? false : setopen(p => p ? "open" : "closed")} tabindex="0"  className={`selectName focusedSelect`}>{selv?.name ? selv?.name : values[0]?.name } <IoMdArrowDropdown /></div>

                            
                            <nav className={'dropDownBox ' + (open)}>
                                <input type='search' onChange={(d) => setsearch(d?.target?.value)} name='q' placeholder='Search..' />
                                <div>
                                    {values?.map((v, k) => {
                                        let visible = true;
                                        if (search) {
                                            if (v?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) == -1) {
                                                visible = false;
                                            }
                                        }
                       
                                        if (visible) {

                                            
                                            return (
                                                <a href='javascript:void(0)' className={v?.val == value ? "selected" : "none"} onClick={() => {setv(v?.val); setselv(v); setopen("closed"); }} key={k}>{v?.name}</a>
                                            );
                                        }
                                    })}
                                </div>
                                
                            </nav>
                        </div>
                        <input type='hidden' name={name} value={selv?.val} />
                    </>
                : 
                    <>
                        {type == "textarea" ? 
                            <textarea autoFocus={autoFocus} style={inputStyle} disabled={disabled} onFocus={() => setfocus(true)} onBlur={() => setfocus(false)} name={name} onChange={(e) => setv(e?.target?.value)} placeholder={placeholder} defaultValue={v}></textarea>
                        : 
                            <input autoFocus={autoFocus} autoComplete="new-password" type={type} style={inputStyle} disabled={disabled} onFocus={() => setfocus(true)} onBlur={() => setfocus(false)} name={name} onChange={(e) => setv(e?.target?.value)} value={v} placeholder={placeholder} />
                        }
                       </>
                    
                }
                {!v && type !="select" && <hr />}
                {error && <p>{error}</p>}
            </label>
    )
}


export function MultiSelect({title, onChange, style, validate, inputStyle, values, value}) { 


    const [open, setopen] = useState(false);
    const [va, setv] = useState(value ? value : []);
    const [vtext, setvtext] = useState("");
    const [search, setsearch] = useState(""); 

    

    useEffect(() => {

        const closeFunc = (e) => {
            const selClose = e?.target.closest(".selectBox");
            if (!selClose) {
                setopen(false)
            }
        }

        window.addEventListener("click", closeFunc);

        return () =>{
            window.removeEventListener("click", closeFunc);
        }

    }, []);

    function checked(d) {
        
        let ck = va?.filter(r => r == d);
        if (ck?.length) {
            setv(p => [...p?.filter(r => r != d)]);
        } else {
            setv(p => [...p, d]);
        }



    }

    

    useEffect(() => {
        if (value) {
            setv(value);
        }

    }, []);
    

    useEffect(() => {

        
        onChange(va);

        let res = [];
        va?.map((r, k) => {
            let rV = values?.filter(c => c?.val == r);
            if (rV) {
                res.push(rV[0]?.name);
            }
            
        });

        

        setvtext(res.join(", "));
    }, [va])
    



    return (
        <label style={style}  className={"formInputBox focused"}>
            <span>{title ? `${title}:` : false} {validate && <b>*</b>}</span>

            <div className='selectBox'>
                <div style={inputStyle} onClick={() => setopen(p => !p)} className='selectName'>{vtext ? vtext : "--"} <IoMdArrowDropdown /></div>
                <nav className={`dropDownBox ${open ? "open" : "closed"}`}>
                    <input type='search' onChange={(d) => setsearch(d?.target?.value)} name='q' placeholder='Search..' />
                    <div className='multiSelectBox'>
                        {values?.map((v, k) => {

                            let visible = true;
                            if (search) {
                                if (v?.name?.indexOf(search) == -1) {
                                    visible = false;
                                }
                            }
                            
                            
                            if (visible) {
                                let checkedD = va?.filter(p => p == v?.val)?.length;

                                if (v?.val) {
                                    return(
                                        <label onClick={() => { checked(v?.val); }} key={k}>{checkedD ? <IoIosCheckbox />:<MdOutlineCheckBoxOutlineBlank />} {v?.name}</label>
                                    );
                                } else {
                                    return (
                                        <label key={k}>{v?.name}</label>
                                    );
                                    
                                }
                            }
                        })}
                        
                    </div>

                </nav>
            </div>
        </label>
    );
}


export function ImageUpload({ title, validate, value, onChange, multiple, style, type }) {
    
    if (!multiple) {
        multiple = false;
    }
    
    let defaultImg = `${Config?.uploadFolder}nopic.jpg`; 

    const [images, setimages] = useState([value ? value : defaultImg]);
    const [loader, setloader] = useState(false);


    const {success} = useContext(AppContext);
    const [open, setopen] = success;

    const [multiImg, setmultiImg] = useState([]);


    if (!type) {
        type = "img";
    }

    async function upload(event) { 
        event.preventDefault();

        
        if (event.target.files && event.target.files[0]) {

            const formData = new FormData();

            // Update the formData object
            formData.append(
                "files",
                event.target.files[0],
            );

            const requestOptions = {
                method: 'POST',
                credentials : "include",
                body:formData
            };
            

            try {

                const res = await fetch(Config.domain+"upload", requestOptions);
                if (res.status != 200) {

                    setopen({
                        title:"Error",
                        short:"Server Error",
                        error:true
                    });
                    console.log(res);
                    
                } else {
        
                    const data = await res?.json();
                    if (data?.error) {
                        setopen({
                            title:"Error",
                            short:data?.error,
                            error:true
                        });
                    } else {
                        setimages(data);
                        if (data?.length > 1) {
                            onChange(data);
                        } else {
                            onChange(data[0]);
                        }
                    }
                    
                }
            } catch(e) {
                console.log(e?.message);
            }

        }

    }

    
    return (
        <>
            {loader && <Loader />}
        
            <label style={style}  className={"formInputBox focused"}>
                    
                <span>{title ? `${title}:` : false} {validate && <b>*</b>}</span>
                
                <div style={{display:"flex", justifyContent:"flex-start", gap:10, flexWrap:"wrap"}}>
                    {images?.map((v, k) => {

                        return (
                            <img className='uploadForm Img' src={`${Config?.imgUrl}${v}`} key={k} />
                        );
                    })}
                </div>
                
                

                <input className='uploadField' multiple={multiple} onChange={upload} type='File' />
            </label>
        </>

    );
}


export function FormUpload({ value, onChange, multiple, type }) {
    
    if (!multiple) {
        multiple = false;
    }
    
    let defaultImg = `${Config?.uploadFolder}nopic.jpg`; 

    const [images, setimages] = useState([value ? value : defaultImg]);
    const [loader, setloader] = useState(false);


    const {success} = useContext(AppContext);
    const [open, setopen] = success;

    const [multiImg, setmultiImg] = useState([]);


    if (!type) {
        type = "img";
    }

    async function upload(event) { 
        event.preventDefault();

        
        if (event.target.files && event.target.files[0]) {

            const formData = new FormData();

            // Update the formData object
            formData.append(
                "files",
                event.target.files[0],
            );

            const requestOptions = {
                method: 'POST',
                credentials : "include",
                body:formData
            };
            

            try {

                const res = await fetch(Config.domain+"upload", requestOptions);
                if (res.status != 200) {

                    setopen({
                        title:"Error",
                        short:"Server Error",
                        error:true
                    });
                    console.log(res);
                    
                } else {
        
                    const data = await res?.json();
                    if (data?.error) {
                        setopen({
                            title:"Error",
                            short:data?.error,
                            error:true
                        });
                    } else {
                        setimages(data);
                        if (data?.length > 1) {
                            onChange(data);
                        } else {
                            onChange(data[0]);
                        }
                    }
                    
                }
            } catch(e) {
                console.log(e?.message);
            }

        }

    }

    
    return (
        <>
            {loader && <Loader />}

            <input className='uploadField' multiple={multiple} onChange={upload} type='File' />

        </>

    );
}